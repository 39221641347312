<template>
  <BaseFilterDialogAndChips
    :submitted-values="submittedValues"
    :show-reset-button="showResetButton"
    @open="copyFilterParams"
    @clear:filter="clearFilter"
    @reset="resetFilters"
    @submit="submitFilters"
  >
    <v-row dense>
      <v-col cols="12" sm="6">
        <ContactAutocomplete
          v-model="params.client_id"
          :label="$t('general.contact')"
          @update:initial-item="updateAutocompleteValue('client_id', $event)"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field v-model="params.number" :label="$t('general.invoice_no')" />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput
          v-model="params.document_date_from"
          :label="$t('filters.document_date_from')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput
          v-model="params.document_date_to"
          :label="$t('filters.document_date_to')"
        />
      </v-col>
    </v-row>
  </BaseFilterDialogAndChips>
</template>

<script>
import BaseFilterDialogAndChips from '@/components/base/BaseFilterDialogAndChips';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import ContactAutocomplete from '@/components/autocompletes/ContactAutocomplete';
import filterMixin from '@/mixins/filter-mixin';
import { getDefaultSentInvoiceFilterParams } from '@/store/modules/sent-invoices-module';

export default {
  name: 'SentInvoiceFilter',

  components: { ContactAutocomplete, BaseDatepickerInput, BaseFilterDialogAndChips },

  data() {
    return {
      defaultParams: getDefaultSentInvoiceFilterParams(),
    };
  },

  mixins: [filterMixin],

  computed: {
    submittedValues() {
      const values = [];
      const { client_id, number, document_date_from, document_date_to } = this.filterParams;

      if (client_id && this.autocompleteValues.client_id?.id === client_id) {
        values.push({
          key: 'client_id',
          value: this.autocompleteValues.client_id.name,
          label: this.$t('general.contact'),
        });
      }

      if (number) {
        values.push({
          key: 'document_date_from',
          value: number,
          label: this.$t('general.invoice_no'),
        });
      }

      if (document_date_from) {
        values.push({
          key: 'document_date_from',
          value: document_date_from,
          label: this.$t('filters.document_date_from'),
        });
      }

      if (document_date_to) {
        values.push({
          key: 'document_date_to',
          value: document_date_to,
          label: this.$t('filters.document_date_to'),
        });
      }

      return values;
    },
  },
};
</script>

<style scoped></style>
