<template>
  <v-container fluid>
    <v-tabs class="elevation-1 table-wrapper">
      <v-tab @click="fetchSentInvoices(sentInvoiceFilterParams)">
        {{ $t('general.sent_invoices') }}
      </v-tab>

      <v-tab-item>
        <div class="d-flex align-center justify-end px-4">
          <SentInvoiceFilter
            :filter-params="sentInvoiceFilterParams"
            @change="$router.push({ name: 'sentInvoices', query: $event })"
            @reset="resetSentInvoiceFilters"
          />

          <v-btn
            :loading="downloadingSentInvoices"
            :disabled="downloadingSentInvoices"
            icon
            @click="downloadSentInvoices"
          >
            <v-icon>file_download</v-icon>
          </v-btn>
        </div>

        <SentInvoiceTable
          :items="sentInvoices"
          :loading="loadingSentInvoices"
          :pagination="sentInvoicePagination"
          @click:row="openDocument"
          @delete="deleteSentInvoice"
          @edit="$router.push({ name: 'editSentInvoice', params: { id: $event.id } })"
          @toggle:seen="toggleSeenSentInvoice"
          @update:page="onPageChange"
        />
      </v-tab-item>
    </v-tabs>

    <BasePrimaryActionButton
      icon="file_upload"
      @click="$router.push({ name: 'createSentInvoice' })"
    />

    <router-view />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';
import SentInvoiceTable from '@/components/tables/SentInvoiceTable';
import SentInvoiceFilter from '@/components/filters/SentInvoiceFilter';
import { getDefaultSentInvoiceFilterParams } from '@/store/modules/sent-invoices-module';
import { getSanitizedFilterParams } from '@/util/filter-params';
import { openFile } from '@/util/event-bus';

export default {
  name: 'SentInvoices',

  components: {
    BasePrimaryActionButton,
    SentInvoiceTable,
    SentInvoiceFilter,
  },

  computed: {
    ...mapState('sentInvoices', [
      'sentInvoices',
      'sentInvoicePagination',
      'sentInvoiceFilterParams',
      'downloadingSentInvoices',
      'loadingSentInvoices',
    ]),
  },

  created() {
    this.fetchSentInvoices(getSanitizedFilterParams(this.$route.query));
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (
      JSON.stringify(this.sentInvoiceFilterParams) !== JSON.stringify(params) &&
      to.name === 'sentInvoices'
    ) {
      this.fetchSentInvoices(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('sentInvoices', [
      'fetchSentInvoices',
      'deleteSentInvoice',
      'downloadSentInvoices',
      'toggleSeenSentInvoice',
    ]),

    onPageChange(page) {
      const query = { ...this.sentInvoiceFilterParams, page };
      if (page === 1) {
        delete query.page;
      }
      this.$router.push({ name: 'sentInvoices', query });
    },

    resetSentInvoiceFilters() {
      const defaultFilters = getDefaultSentInvoiceFilterParams();
      if (JSON.stringify(defaultFilters) === JSON.stringify(this.sentInvoiceFilterParams)) {
        return;
      }
      this.$router.push({ name: 'sentInvoices', query: defaultFilters });
    },

    openDocument(attachment) {
      openFile({
        name: attachment.file_name,
        url: `api/document-download/${attachment.id}`,
      });
    },
  },
};
</script>
